<template>
    <div style="text-align: left;">
        <div class="member p-30">
            <el-button-group>
                <el-button :type="type==0?'primary':''" @click="setType(0)" size="">个人名片</el-button>
                <el-button :type="type==1?'primary':''" @click="setType(1)" size="">员工名片</el-button>
                <el-button :type="type==2?'primary':''" @click="setType(2)" size="">求购评论</el-button>
                <el-button :type="type==3?'primary':''" @click="setType(3)" size="">我的评论</el-button>
            </el-button-group>
            <div class="df-jc-c" v-show="type==3">
                <div class="tabs df-ai-c m-t-20">
                    <div class="tab" :class="select==0?'select':''" @click="select=0">对个人名片</div>
                    <div class="tab" :class="select==1?'select':''" @click="select=1">对员工名片</div>
                    <div class="tab" :class="select==2?'select':''" @click="select=2">对求购信息</div>
                </div>
            </div>
            <personageCard v-if="type==0 ||(type==3 && select==0)" :type="type"></personageCard>
            <staffCard v-if="type==1 ||(type==3 && select==1)" :type="type"></staffCard>
            <askBuyComment v-if="type==2 ||(type==3 && select==2)" :type="type"></askBuyComment>
            <!-- 
            <personageCard v-if="type==3 && select==0"></personageCard>
            <staffCard v-if="type==3 && select==1"></staffCard>
            <askBuyComment v-if="type==3 && select==2"></askBuyComment> -->
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { ticket, financial } from "@/service"
import personageCard from "./components/personageCard.vue"
import staffCard from "./components/staffCard.vue"
import askBuyComment from "./components/askBuyComment.vue"
export default {
    name: "comment",
    components: {
        personageCard,
        staffCard,
        askBuyComment,
    },
    data() {
        return {
            type: 0,
            select: this.$route.query.select || 0,

        }
    },
    mounted() {


    },
    methods: {
        setType(v) {
            if (this.type == v) return

            this.type = null
            setTimeout(() => {
                this.type = v
            },100)

        },
        back(v) {
            if (v.type == 'setSelect') {
                this.select = v.value
            }
        }
    }
}
</script>
<style scoped lang="less">
@import "./comment.less";
</style>