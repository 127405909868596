<template>
    <div style="text-align: left;background: #FFFFFF;">
        <el-table :data="prlductData.content" style="width: 100%" class="m-t-10">
            <el-table-column label="评论用户信息" v-if="type==0">
                <div class="user" slot-scope="scope">
                    <img :src="$oucy.ossUrl+scope.row.userDetailFull.detailAvatar" class="head" alt="">
                    <div class="name">{{scope.row.userDetailFull.detailNickname}}</div>
                </div>
            </el-table-column>
            <el-table-column label="所评论对象的个人名片" v-else>
                <div class="user" slot-scope="scope">
                    <img :src="$oucy.ossUrl+scope.row.cardPersonFull.cardPic" class="head" alt="">
                    <div class="name">
                        <div >{{scope.row.cardPersonFull.cardName}}</div>
                        <div class="c-2 f12">{{scope.row.cardPersonFull.cardSelf}}</div>
                    </div>
                </div>
            </el-table-column>
            <el-table-column prop="cardPersonCommentContent" label="评论内容"></el-table-column>
            <el-table-column prop="cardPersonCommentCreatetime" label="评论时间"></el-table-column>
            <el-table-column prop="date" label="操作" width="150px">
                <div slot-scope="scope">
                    <el-button type="primary" size="mini" @click="goto(scope.row.cardPersonFull.id)">查看名片</el-button>
                </div>
            </el-table-column>
        </el-table>
        <el-pagination class="zdfy" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
        </el-pagination>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { cardPersonComment,cardPerson ,cardpersoncomment} from "@/service"
export default {
    name: "personageCard",
    components: {},
    props: {
        type: {
            type: [String,Number],
            default: 0,
        },
    },
    data() {
        return {
            prlductData: {},
            cardPerson: null,
            limit: 20,
            start: 0,
            userInfo: localGet('loginUser') || {},
        }
    },
    mounted() {
        this.ajax()
    },
    methods: {
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            me.ajax();
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            me.ajax();
        },
        ajax() {
            if (this.type == 0) {
                if(this.cardPerson){
                    this.getMyCardPersonCommentList()
                }else{
                    this.getMyCardPersonDetail()
                }
            } else {
                this.getMyCommentList()
            }
        },
        getMyCardPersonDetail() {
            cardPerson.getMyCardPersonDetail({}).then(res => {
                this.cardPerson = res.id
                this.getMyCardPersonCommentList()
            })
        },
        // 0
        getMyCardPersonCommentList() {
            cardPersonComment.getMyCardPersonCommentList({
                limit: this.limit,
                start: this.start,
                cardPerson: this.cardPerson,
                // cardStatusEnum:2
            }).then(res => {
                this.prlductData = res||{}
            })
        },
        getMyCommentList() {
            cardPersonComment.getMyCommentList({
                limit: this.limit,
                start: this.start,
            }).then(res => {
                this.prlductData = res||{}
            })
        },
        goto(id){
            this.$oucy.go('/personageDetail?id='+id)
        },
    }
}
</script>
<style scoped lang="less">
.payimg {
    display: flex;
    align-content: center;
}

.zdtitle {
    padding-bottom: 15px;
}

.zdfy {
    padding-top: 20px;
}

.user {
    display: flex;
    align-items: center;
}

.head {
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.name {
    font-size: 14px;
    font-family: MicrosoftYaHei-, MicrosoftYaHei;
    font-weight: normal;
    color: #555C68;
    margin-left: 20px;
}
</style>