<template>
    <div style="text-align: left;background: #FFFFFF;">
        <el-table :data="prlductData.content" style="width: 100%" class="m-t-10">
            <el-table-column prop="orderMemberCardLevelRemark" label="评论用户信息">
                <div class="user" slot-scope="scope">
                    <img :src="$oucy.ossUrl + scope.row.commentUserAvata" class="head" alt="">
                    <div class="name line-1">{{scope.row.commentUserNickname}}</div>
                </div>
            </el-table-column>
            <el-table-column prop="orderMemberCardStateDays" label="评论内容" width="530">
                <div slot-scope="scope">
                    {{scope.row.commentContent}}
                    <div class="boxs">
                        <template v-if="scope.row.searchSpuList">
                            <div class="box" v-for="(v,i) of scope.row.searchSpuList" :key="i">
                                <img src="$oucy.ossUrl+ spuItem.cover" class="img" alt="">
                                <div class="name">
                                    <div class="line-1">{{v.spuName}}</div>
                                    <div class="f12 c-m">￥{{v.spuMinPrise}}</div>
                                </div>
                            </div>
                        </template>
                        <template v-if="scope.row.enterpriseDetailList">
                            <div class="box" v-for="(v,i) of scope.row.enterpriseDetailList" :key="i">
                                <img src="$oucy.ossUrl+ spuItem.enterpriseAvata" class="img" alt="">
                                <div class="name">
                                    <div class="line-1">{{v.enterpriseName}}</div>
                                    <!--   <el-rate class="m-r-30 " v-else value="5" disabled disabled-void-color="#888888" void-color="#888888"></el-rate> -->
                                    <el-rate v-model="data.enterpriseStar" disabled></el-rate>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </el-table-column>
            <el-table-column prop="orderMemberCardStateDays" label="求购信息">
                <div class="user pointer" slot-scope="scope" @click="goto(scope.row.buyAskFull.id)">
                    <!-- <img src="https://aliyunoss.lesoujia.com/dynamic-boss/3e903690d58f4505a5bb67715874b77a.png" class="img" alt=""> -->
                    <div class="imgList" v-for="(buyAskItem,buyAskIndex) in scope.row.buyAskFull_all.buyAskForms" :key="buyAskIndex">
                        <div v-for="(vItem,vIndex) in buyAskItem.value" :key="vIndex" v-if="buyAskItem.buyAskCategoryForm.formType == 7">
                            <img :src="$oucy.ossUrl + vItem.value" class="img" border-radius="20rpx" v-if="vItem.type == 'image'"></img>
                            <img :src="$oucy.ossUrl + vItem.value + '?x-oss-process=video/snapshot,t_0,f_jpg'" class="img" border-radius="20rpx" v-if="buyAskItem.buyAskCategoryForm.type == 'video'">
                            </img>
                        </div>
                    </div>
                    <div class="name">
                        <div class="line-1">{{scope.row.buyAskFull_all.askTitle}}</div>
                        <div class="c-2 f12 line-1">
                            <div v-for="(buyDescItem,buyDescIndex) in scope.row.buyAskFull_all.buyAskForms" :key="buyDescIndex" v-if="buyDescItem.buyAskCategoryForm.formType == 2">{{buyDescItem.value}}</div>
                        </div>
                    </div>
                </div>
            </el-table-column>
            <el-table-column prop="buyAskFull_all.askLatestTime" label="评论时间"></el-table-column>
        </el-table>
        <el-pagination class="zdfy" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
        </el-pagination>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { financial, buyAsk } from "@/service"
export default {
    name: "askBuyComment",
    components: {},
    props: {
        type: {
            type: [String,Number],
            default: 0,
        },
    },
    data() {
        return {
            prlductData: {},
            limit: 20,
            start: 0,
            userInfo: localGet('loginUser') || {},
        }
    },
    mounted() {
        this.ajax()
    },
    methods: {
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            me.ajax();
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            me.ajax();
        },
        ajax() {
            if (this.type == 2) {
                this.getMyBuyAskComment()
            } else {
                this.getMyCommentList()
            }
        },
        getMyBuyAskComment() {
            buyAsk.getMyBuyAskComment({
                limit: this.limit,
                start: this.start,
            }).then(res => {
                if (res.content) {

                    for (var i = 0; i < res.content.length; i++) {
                        if (res.content[i].buyAskFull_all.buyAskForms) {
                            for (var j = 0; j < res.content[i].buyAskFull_all.buyAskForms.length; j++) {
                                if (res.content[i].buyAskFull_all.buyAskForms[j].buyAskCategoryForm
                                    .formType == 7) {
                                    res.content[i].buyAskFull_all.buyAskForms[j].value = JSON.parse(res
                                        .content[i].buyAskFull_all.buyAskForms[j].value);
                                }
                            }
                        }
                    }
                }
                this.prlductData = res||{}
            })
        },
        // 
        getMyCommentList() {
            buyAsk.getMyCommentList({
                limit: this.limit,
                start: this.start,
            }).then(res => {
                if (res.content) {

                    for (var i = 0; i < res.content.length; i++) {
                        if (res.content[i].buyAskFull_all.buyAskForms) {
                            for (var j = 0; j < res.content[i].buyAskFull_all.buyAskForms.length; j++) {
                                if (res.content[i].buyAskFull_all.buyAskForms[j].buyAskCategoryForm
                                    .formType == 7) {
                                    res.content[i].buyAskFull_all.buyAskForms[j].value = JSON.parse(res
                                        .content[i].buyAskFull_all.buyAskForms[j].value);
                                }
                            }
                        }
                    }
                }
                this.prlductData = res
            })
        },
        goto(id){
            this.$oucy.go('/askBuyDetail?id='+id)
        },
    }
}
</script>
<style scoped lang="less">
.payimg {
    display: flex;
    align-content: center;
}

.zdtitle {
    padding-bottom: 15px;
}

.zdfy {
    padding-top: 20px;
}

.user,
.box {
    display: flex;
    align-items: center;
}

.head,
.img {
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.img {
    border-radius: 0px;
}

.name {
    font-size: 14px;
    font-family: MicrosoftYaHei-, MicrosoftYaHei;
    font-weight: normal;
    color: #555C68;
    margin-left: 20px;
}

.boxs {
    display: flex;
    flex-wrap: wrap;
}

.box {
    width: 48%;
    background: #F0F0F0;
    padding: 10px;
    margin: 5px;

    &:hover {
        background: #fff;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
    }

    .name {
        margin-left: 10px;
    }

}
</style>