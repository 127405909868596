<template>
    <div style="text-align: left;background: #FFFFFF;">
        <el-table :data="prlductData.content" style="width: 100%" class="m-t-10">
            <el-table-column prop="orderMemberCardLevelRemark" label="评论用户信息">
                <div class="user" slot-scope="scope">
                    <img :src="$oucy.ossUrl+scope.row.ltUserDetailFull.detailAvatar" class="head" alt="">
                    <div class="name">{{scope.row.ltUserDetailFull.detailNickname}}</div>
                </div>
            </el-table-column>
            <el-table-column prop="commentContent" label="评论内容"></el-table-column>
            <el-table-column prop="orderMemberCardStateDays" label="被评论的员工名片">
                <div class="user" slot-scope="scope">
                    <img :src="$oucy.ossUrl+scope.row.cardEmployeeFull.employeePic" class="head" alt="">
                    <div class="name">
                        <div >{{scope.row.cardEmployeeFull.employeeName}}</div>
                        <div class="c-2 f12">{{scope.row.cardEmployeeFull.employeeSignature}}</div>
                    </div>
                </div>
            </el-table-column>
            <el-table-column prop="commentCreatetime" label="评论时间"></el-table-column>
            <el-table-column prop="date" label="操作" width="150px">
                <div slot-scope="scope">
                    <el-button type="primary" size="mini" @click="goto(scope.row.cardEmployeeFull.id)">查看名片</el-button>
                </div>
            </el-table-column>
        </el-table>
        <el-pagination class="zdfy" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
        </el-pagination>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { cardEmployeeComment } from "@/service"
export default {
    name: "staffCard",
    components: {},
    props: {
        type: {
            type: [String,Number],
            default: 0,
        },
    },
    data() {
        return {
            prlductData: {},
            limit: 20,
            start: 0,
            userInfo: localGet('loginUser') || {},
        }
    },
    mounted() {
        this.ajax()
    },
    methods: {
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            me.ajax();
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            me.ajax();
        },
        ajax() {
            if (this.type == 3) {
                this.getMyCommentList()
            } else {
                this.getMyCardEmployeeCommentList()
            }
        },
        getMyCardEmployeeCommentList() {
            cardEmployeeComment.getMyCardEmployeeCommentList({
                limit: this.limit,
                start: this.start,
                
            }).then(res => {
                this.prlductData = res||{}
            })
        },
        getMyCommentList() {
            cardEmployeeComment.getMyCommentList({
                limit: this.limit,
                start: this.start,
                
            }).then(res => {
                this.prlductData = res||{}
            })
        },
        goto(id){
            this.$oucy.go('/staffDetail?id='+id)
        },
    }
}
</script>
<style scoped lang="less">
.payimg {
    display: flex;
    align-content: center;
}

.zdtitle {
    padding-bottom: 15px;
}

.zdfy {
    padding-top: 20px;
}

.user {
    display: flex;
    align-items: center;
}

.head {
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.name {
    font-size: 14px;
    font-family: MicrosoftYaHei-, MicrosoftYaHei;
    font-weight: normal;
    color: #555C68;
    margin-left: 20px;
}
</style>